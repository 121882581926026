<template>
  <section id="nosotros-ayudamos">
    <div class="container jumbotron">
      <div class="row justify-content-center">
        <div class="col-10">
          <h2 class="section-title">AVISO LEGAL</h2>
          <p>
            En el presente Aviso Legal, el Usuario, podrá encontrar toda la
            información relativa a las condiciones legales que definen las
            relaciones entre los usuarios y el responsable de la página web
            accesible en la dirección URL https://ibancar.com (en adelante, el
            Sitio Web), que IBANCAR WORLD SL (en adelante, el Titular) pone a
            disposición de los usuarios de Internet. La utilización del sitio
            web implica la aceptación plena y sin reservas de todas y cada una
            de las disposiciones incluidas en este Aviso Legal. En consecuencia,
            el usuario del sitio web debe leer atentamente el presente Aviso
            Legal en cada una de las ocasiones en que se proponga utilizar la
            web, ya que el texto podría sufrir modificaciones a criterio del
            titular de la web, o a causa de un cambio legislativo,
            jurisprudencial o en la práctica empresarial.
          </p>
          <h5>1.- DATOS DEL RESPONSABLE TITULAR DEL SITIO WEB</h5>
          <p>
            Nombre del titular: IBANCAR WORLD SL Domicilio social: 
            Calle Rafael Muntaner Nº 1, 2ª planta, Código Postal 29004 Málaga
            C.I.F.: B93145001 Teléfono de contacto: 952864645 Correo
            electrónico: info@ibancar.com IBANCAR WORLD SL es el responsable del
            Sitio Web y se compromete a cumplir con todos los requisitos
            nacionales y europeos que regulan el uso de los datos personales de
            los usuarios. Este Sitio Web garantiza la protección y
            confidencialidad de los datos personales que nos proporcionen de
            acuerdo con lo dispuesto en el Reglamento General de Protección de
            Datos de Carácter Personal (UE) 2016/679 del Parlamento Europeo y
            del Consejo, de 27 de abril de 2016, en la Ley Orgánica 3/2018, de
            Protección de Datos Personales y garantía de los derechos digitales,
            así como en la Ley de Servicios de la Sociedad de la Información y
            Comercio Electrónico 34/2002 de 11 de Julio (LSSI-CE).
          </p>
          <h5>2.- OBJETO</h5>
          <p>
            El Sitio Web facilita a los usuarios del mismo el acceso a
            información y servicios prestados por el Titular a aquellas personas
            u organizaciones interesadas en los mismos. El acceso y la
            utilización del Sitio Web atribuye la condición de usuario del Sitio
            Web (en adelante, el “Usuario”) e implica la aceptación de todas las
            condiciones incluidas en este Aviso Legal así como de sus
            modificaciones. La prestación del servicio del Sitio Web tiene una
            duración limitada al momento en el que el Usuario se encuentre
            conectado al Sitio Web o a alguno de los servicios que a través del
            mismo se facilitan. Por tanto, el Usuario debe leer atentamente el
            presente Aviso Legal en cada una de las ocasiones en que se proponga
            utilizar el Sitio Web, ya que éste y sus condiciones de uso
            recogidas en el presente Aviso Legal pueden sufrir modificaciones.
          </p>
          <h5>3.- ACCESO Y UTILIZACIÓN DE LA WEB</h5>
          <p>
            El acceso a la web tiene carácter gratuito para los usuarios de la
            misma, salvo en lo relativo al coste de la conexión a través de la
            red de telecomunicaciones suministrada por el proveedor de acceso
            contratado por los usuarios. Con carácter general el acceso y
            utilización de la web no exige la previa suscripción o registro de
            los usuarios de la misma. Los usuarios garantizan y responden, en
            cualquier caso, de la exactitud, vigencia y autenticidad de los
            datos personales facilitados, y se comprometen a mantenerlos
            debidamente actualizados. El usuario acepta proporcionar información
            completa y correcta en el formulario de contacto o suscripción. En
            ningún caso se recabarán del menor de edad datos relativos a la
            situación profesional, económica o a la intimidad de los otros
            miembros de la familia, sin el consentimiento de éstos. Si eres
            menor de catorce años y has accedido a este sitio web sin avisar a
            tus padres no debes registrarte como usuario. En esta web se
            respetan y cuidan los datos personales de los usuarios. Como
            usuario, debes saber que tus derechos están garantizados.
          </p>
          <h5>4.- CONTENIDOS DE LA WEB</h5>
          <p>
            El idioma utilizado por el titular en la web será el castellano. El
            Titular no se responsabiliza de la no comprensión o entendimiento
            del idioma de la web por el usuario, ni de sus consecuencias. El
            Titular podrá modificar los contenidos sin previo aviso, así como
            suprimir y cambiar éstos dentro de la web, como la forma en que se
            accede a éstos, sin justificación alguna y libremente, no
            responsabilizándose de las consecuencias que los mismos puedan
            ocasionar a los usuarios. Se prohíbe el uso de los contenidos de la
            web para promocionar, contratar o divulgar publicidad o información
            propia o de terceras personas sin la autorización de el Titular, ni
            remitir publicidad o información valiéndose para ello de los
            servicios o información que se ponen a disposición de los usuarios,
            independientemente de si la utilización es gratuita o no. Los
            enlaces o hiperenlaces que incorporen terceros en sus páginas web,
            dirigidos a esta web, serán para la apertura de la página web
            completa, no pudiendo manifestar, directa o indirectamente,
            indicaciones falsas, inexactas o confusas, ni incurrir en acciones
            desleales o ilícitas en contra de aquel.
          </p>
          <h5>5.- MEDIDAS DE SEGURIDAD</h5>
          <p>
            Los datos personales comunicados por el usuario podrán ser
            almacenados en bases de datos automatizadas o no, cuya titularidad
            corresponde en exclusiva al Titular, asumiendo todas las medidas de
            índole técnica, organizativa y de seguridad que garanticen la
            confidencialidad, integridad y calidad de la información contenida
            en las mismas de acuerdo con lo establecido en las normativas
            vigentes en materia de protección de datos de carácter personal.
          </p>
          <h5>6.- LIMITACIÓN DE RESPONSABILIDAD</h5>
          <p>
            Tanto el acceso a la web como el uso no consentido que pueda
            efectuarse de la información contenida en la misma es de la
            exclusiva responsabilidad de quien lo realiza. El Titular no
            responderá de ninguna consecuencia, daño o perjuicio que pudieran
            derivarse de dicho acceso o uso. Tampoco se hace responsable de los
            errores de seguridad, que se puedan producir ni de los daños que
            puedan causarse al sistema informático del usuario (hardware y
            software), o a los ficheros o documentos almacenados en el mismo,
            como consecuencia de: - la presencia de un virus en el ordenador del
            usuario que sea utilizado para la conexión a los servicios y
            contenidos de la web. - un mal funcionamiento del navegador. - y/o
            del uso de versiones no actualizadas del mismo. El Titular no se
            hace responsable de la fiabilidad y rapidez de los hiperenlaces que
            se incorporen en la web para la apertura de otras. Tampoco garantiza
            la utilidad de estos enlaces, ni se responsabiliza de los contenidos
            o servicios a los que pueda acceder el usuario por medio de estos
            enlaces, ni del buen funcionamiento de estas webs. El Titular no
            será responsable de los virus o demás programas informáticos que
            deterioren o puedan deteriorar los sistemas o equipos informáticos
            de los usuarios al acceder a su web u otras webs a las que se haya
            accedido mediante enlaces de esta web.
          </p>
          <h5>7.- EMPLEO DE LA TECNOLOGÍA “COOKIE”</h5>
          <p>
            El Sitio Web puede emplear cookies o tecnologías similares que se
            regirán por lo establecido en la Política de Cookies, accesible en
            todo momento y respetando la confidencialidad e intimidad del
            usuario, siendo parte integrante del presente Aviso Legal.
          </p>
          <h5>8.- NAVEGACIÓN</h5>
          <p>
            Los servidores de Internet pueden recoger datos no identificables,
            que puedan incluir, direcciones IP, y otros datos que no pueden ser
            utilizados para identificar al usuario. Su dirección IP se
            almacenará en los logs de acceso de forma automática y con la única
            finalidad de permitir el tránsito por Internet, siendo necesario que
            su equipo facilite esta dirección IP cuando navega por Internet para
            que las comunicaciones puedan realizarse. Así mismo, la dirección IP
            podrá ser utilizada para realizar estadísticas, de manera
            anonimizada, sobre el número de visitantes de esta web y su
            procedencia, de forma totalmente transparente a su navegación.
          </p>
          <h5>9.- PROPIEDAD INTELECTUAL E INDUSTRIAL</h5>
          <p>
            El usuario conoce y acepta que todos los contenidos y/o cualesquiera
            otros elementos del sitio web son propiedad del Titular, y se
            compromete a respetar los derechos de propiedad intelectual e
            industrial titularidad del mismo y/o de terceros. Cualquier uso de
            la web o sus contenidos deberá tener un carácter exclusivamente
            particular Está reservado exclusivamente al Titular cualquier otro
            uso que suponga la copia, reproducción, distribución,
            transformación, comunicación pública o cualquier otra acción
            similar, de todo o parte de los contenidos de la web, por lo que
            ningún usuario podrá llevar a cabo estas acciones sin la
            autorización previa y por escrito de aquel.
          </p>
          <h5>10.- LEGISLACIÓN APLICABLE Y JURISDICCIÓN COMPETENTE</h5>
          <p>
            El presente Aviso Legal se interpretará y regirá de conformidad con
            la legislación española. IBANCAR WORLD SL y los usuarios, con
            renuncia expresa a cualquier otro fuero que pudiera corresponderles,
            se someten al de los juzgados y tribunales del domicilio del usuario
            para cualquier controversia que pudiera derivarse del acceso o uso
            de la web. En el caso de que el usuario tenga su domicilio fuera de
            España, el Titular y el usuario, se someten, con renuncia expresa a
            cualquier otro fuero, a los juzgados y tribunales de la ciudad de
            29640, Fuengirola, Málaga.
          </p>
        </div>
        <!-- /row1 -->
      </div>
    </div>
    <!-- /container -->
  </section>
  <!-- /section -->
</template>

<script>
import { useMeta } from 'vue-meta'
import store from "@/store";

export default {
  name: "AvisoLegal",
  setup() {

  useMeta({
    title: 'Aviso Legal',
    meta:[
      {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
      {name:'description', content:'Préstamo con aval de coche sin cambio de titularidad. Consigue crédito hasta 6.000€ en 24h. Evita rentings y amortiza desde el primer día. Consigue dinero rápido.'},
      {name:'keywords', content:'Préstamo aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero online. Empeña tu coche. Refinanciación de coches.'}
    ]
  })
  }
};
</script>

<style scoped lang="scss">
.jumbotron {
  padding: 25px 0 0 25px;
  margin-top: 90px;
  background-color: #fff;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {
}

@include media-breakpoint-between(md, lg) {
}

@include media-breakpoint-between(xl, xxl) {
}
</style>